<template>
  <div>
    <div style="margin: 20px 0 0 20px"><MyBreadcrumb></MyBreadcrumb></div>
    <div class="site-detail">
      <Detail :isSuper="true"></Detail>
    </div>
  </div>

</template>
<script>
import Detail from './components/detail/detail'
export default {
  name:'site-detail',
  data(){
    return {
    }
  },
  components:{
    Detail
  },
}
</script>
<style scoped lang="scss">
</style>
